const AppMessages = {
  somethingWentWrongMessage: "Something went wrong.",
  campaigns: {
    campaignNameEmpty: "Campaign name should not be empty",
    campaignPause: "This action will pause your scheduled campaign. Do you want to proceed?",
    errMessage: "Something went wrong. Please try again.",
    notVerifiedDomain: "The Domain is not verified.Please check SPF,DKIM and CNAME records for domain",
    senderVerificationResend: "Sender not verified . Please click here to send Verification mail.",
    CampDeleteSuccess: "Campaign deleted successfully",
    campaignCanNotDelete: "Cannot delete this campaign",
    campaignPauseSuccess: "Campaign paused successfully",
    postBoxError: "Error occured in postbox",
    campaignCanNotPause: "This campaign cannot be paused",
    deleteSegRelatedCampaign: "campaign id missing in the request for deleteSegRecCampaigns",
    campSegmentationremoval: "Campaign details are removed from segmentation",
    senderAddedReVerify: "Sender already added. Please verify the account if not verified.",
    campaignTypeExists: "Campaign type already exists",
    campaignTypeAdded: "Campaign type added",
    campaignNameExists: "Campaign already exists",
    campaignNameSuccess: "Campaign added",
    senderAccountNotExists: "No account exists with given sender id",
    senderAccountActivation: "Mirabel's Email Service - Sender Activation",
    testCampaignSuccess: "Test campaign sent.",
    campaignSent: "Your Campaign has been Sent",
    scheduledCampaignFailedSubject: "Alert! Scheduled campaigns not processed",
    sendCampaignFailedSubject: "Alert! Campaign sending failed for ",
    emailTitleExists: "Email name already exists",
    followUpCampaignSuccess: "Follow up campaign created successfully.",
    campaignReplicated: "Campaign replicated successfully.",
    campaignDeleteConfirmation: "Are you sure you want to delete this campaign?",
    footerDeleteConfirmation: "Are you sure you want to delete this footer?",
    headerDeleteConfirmation: "Are you sure you want to delete this header?",
    senderActivationMailSent:
      "An activation mail has been sent to {{sender_Name}}  please click on the link to activate the sender.",
    SenderAddedSuccess: "Sender {{sender_Name}} is added successfully",
    groupLimitMessage: "You cannot use Group with more than  {{TEST_CAMPAIGN_RECIPIENT_LIST_LIMIT}} valid recipients.",
    MaxrecipientsForTestCampaign:
      "Maximum {{TEST_CAMPAIGN_RECIPIENT_SELECT_LIMIT}} recipients allowed for test campaign.",
    DateTimeEmpty: "Date and time should not be empty.",
    sendeName: "Please enter Sender Name",
    sendeEmailID: "Please enter Sender Email ID",
    validEmailID: "Please enter valid Email ID",
    senderAddress: "Please enter Sender Address",
    campaignType: "Please enter campaign type",
    senderNotVerified: "Sender not verified.",
    senderNameEmpty: "Sender name should not be empty.",
    senderNameSize: "Sender name should not be more than 50 characters.",
    noRecipients: "There are no recipients for selected criteria.",
    scheduleDateEmpty: "Schedule date should not be empty.",
    scheduleDateEarly: "Schedule date can not be earlier than Current date.",
    defaultText: "Email content has some default text",
    spamContent: "Selected campaign content might go to spam. Do you want to continue?",
    sendConfirm: "Are you sure you want to continue?",
    campaignSaved: "Campaign saved successfully.",
    selectCampaignType: "Please select campaign type",
    enterCampaignName: "Please enter campaign name",
    clearGroups: "Selected group(s) will be cleared. Do you want to proceed?",
    clearTags: "Selected tag(s) will be cleared. Do you want to proceed?",
    clearSegments: "Selected segment will be cleared. Do you want to proceed?",
    clearAllProfiles: "All Profiles selection will be cleared. Do you want to proceed?",
    templateChangeConfirm:
      "By changing template you will lose your old styles and content . Are you sure want to change the template?",
    abTypeChangeConfirm: "If you change the AB Test criteria, you will lose the content. Do you want to continue?",
    campaignLeaveConfirm: "Changes are not saved do you want to save the changes?",
    campaignTypeEnableMessage:
      "You cannot enable as maximum number of active Campaign Types({{CAMPAIGN_TYPE_LIMIT}}) is reached.",
    nocampaignAssociated: "No campaigns associated",
    campaignTypeDisableMessage: "This campaign type cannot be disabled as this is used in recent campaigns",
    campaignTypeDisabled: "Disabled successfully.",
    campaignTypeEnabled: "Enabled successfully.",
    campaignTypeEnablePrivate: "Campaign type set to private.",
    campaignTypeDisablePrivate: "Campaign type set to public.",
    campaignTypePrivateRecipientExceeded:
      "For Private Campaign Types, the recipient should be less than {{PRIVATE_CAMPAIGN_TYPE_RECIPIENT_LIMIT}}",
    campaignTypePrivateScheduled:
      "When scheduling a campaign with a Private Campaign Type, please ensure that the recipient size does not exceed {{PRIVATE_CAMPAIGN_TYPE_RECIPIENT_LIMIT}}",
    deleteCampaignType:
      "No campaigns associated with this campaign type. This campaign type can be deleted permanently by clicking 'Yes', click 'No' to disable.",
    htmlGetError: "Error occured while fetching Html from the url ",
    templateDetailsError: "Unable to fetch template details",
    templateUploadError: "Unsupported file type. Please upload only Html files ",
    emptyHtml: "HTML should not be empty.",
    emptyTemplate: "Template name can't be empty.",
    emptyHeader: "Header name can't be empty.",
    emptyLpName: "Landing Page name can't be empty.",
    campaignHtmlSaveError: "Error occured in saving campaign html ",
    templateName: "Please Enter Template Name",
    lpName: "Please Enter Landing Page Name",
    templateSaved: "Saved successfully",
    plainTextTemplateSaved: "Template saved successfully",
    senderFieldEmpty: "{{editField}} should not be empty.",
    nameSizeLimit: "Name should not be more than 20 characters.",
    updateSuccess: "Updated successfully.",
    senderDisableMessage: "This sender cannot be disabled as this is used in recent campaigns",
    NocampaignsForSender: "No campaigns sent from this sender",
    senderCampigns: "This sender is involved in {{CampaignCount}}",
    lastCampaignSentdate: ". Last campaign was sent on {{CampaignDate}} .",
    campaignTypeInfoMessage:
      "Choose a Campaign Type relevant to this Email as it plays a key role in Unsubscribe & Update preferences. Ex: Marketing ",
    campaignInfoMessage: `Choose a Campaign relevant to this Email as
      recipients will not receive any Emails associated
      with this campaign if they unsubscribe.
      Ex: Sports Newsletter`,
    campaignPreviewInfo: "Grab recipient’s attention with a short description that drives them to open the Email.",

    campaignEmailNameInfo:
      "It could be a one-time Email (or) an edition of a regular Campaign sent with certain frequency. Ex: Newsletter March 2020 ",
    spamInfoMessage:
      "To comply with anti-spam laws, all promotional emails should have Unsubscribe mechanism, use Opt-out forms to manage Unsubscribes.",
    spamScoreHighInfo: "Click the info icon for factors impacting Spam & Deliverability.",
    senderNameInfo:
      "Sender Name is displayed in the ‘From Address’ of your Campaign, use a familiar Name or Company Name.",
    segmentationInfo: "Use Segmentation to send your Campaigns to specific target audience that meet your criteria.",
    profileCountMessage:
      "This list has {{Profiles}} profile(s), {{Recipients}} recipient(s), {{Unsubscribes}} unsubscribe(s),{{InvaliContact}} invalid contact(s),  {{Others}} others, {{WithoutRep}} without rep emails.",
    spamScoreFields: "Subject, Sender and Email Content are required to activate the Spam Score.",
    senderNotExistsError: "The campaign sender is not in the verified sender list",
    campaignSubjectInfoMessage:
      "Personalize Subject using merge fields. Press ‘@’ to see the list of available merge fields.",
    selectWinnerConfirm:
      "Once you pick a winner you won't be able to change it. The selected version will be sent to the rest of the recipients.",
    hardBouncedAlert: "Unable to send Campaign as {{REASONS}} exceeds  its threshold  value(s)",
    addressHelpText:
      "Address is required to comply with CAN-SPAM laws. As a best practice, use your company address listed on your website.",
    EmailsSentCrossed:
      "Unable to send or schedule a Campaign as monthly limit is ({{SEND_LIMIT}}) , you can send max to ({{AVAILABLE_EMAILS}}) recipients",
    EmailsSentCrossedNextMonth:
      "Unable to send or schedule a Campaign  for the Next month as monthly limit is ({{SEND_LIMIT}}) , you can send max to ({{AVAILABLE_EMAILS}}) recipients",
    ActionHistoryLog: "Campaign failed to send as {{REASONS}} reached",
    SendActionHistoryLog:
      "Campaign failed to {{ACTION}} as Recipients size of {{RECIPIENTS_SIZE}} exceeds available send limit of {{AVAILABLE_LIMIT}}",
    spfDKimExists:
      "We have noticed that there are rep(s) with the domain(s) that is not configured for sending the Emails from Marketing Manager.",
    spfDkimHistory: "Campaign failed to send as {{SPF_DKIM_HISTORY}} is not found for domain(s)({{DOMAINS}})",
    mergeTagEnrollments: "Merge Tags will replace workflow enrollments data.",
    clickPer: "Percentage of unique opens based on emails delivered.",
    openPer: "Click-Through-Rate: Percentage of unique clicks based on unique emails opened.",
    spamPer: "Percentage of spam emails based on number of emails delivered.",
    bouncePer: "Percentage of emails bounced based on number of emails sent.",
    unsubscribePer: "Percentage of emails unsubscribed based on number of emails delivered.",
    invalidTime: "Please choose time for Resend to Non-opens",
    inValidTime: "Please choose time for Resend to Opened but not Clicked",
    footerColorBackgroudMatch: "The Background color and the Font color should be different.",
    domainNotWhitelisted: "Sender cannot be added as email domain is not whitelisted.",
    campaignFooterNameExists: "Footer setting name already exists.",
    campaignHeaderNameExists: "Header setting name already exists.",
    warmupGroupsRemoval: "Unable to remove the group as it is already used in First warm up campaign",
    warmupTagsRemoval: "Unable to remove the tag as it is already used in First warm up campaign",
    warmupSegmentsRemoval: "Unable to remove the segment as it is already used in First warm up campaign",
    dateRangeFilterDateCheck: "To date should be greater than From date.",
    saveCampaignTemplateSuccess: "Campaign Template saved successfully",
    templateNameExists: "Template name already exists. Please enter a different name",
    workflowCampaignPublishMessage:
      "Changes you made to the <strong> {{changedFieldNames}} </strong> will be applied to all the active child campaigns in this sequence.<br /><br /> Are you sure you want to {{actioncampaign}} the campaign?",
    emailContentSaved: "Email content saved successfully",
    firstPublishParent: "To publish the sequence campaign, you have to publish the Parent Campaign.",
    warmUpErrorMessage: `Please select minimum of ${process.env.WARMUP_RECIPIENTS_LIMIT} recipients to schedule or Send the Warm Up campaigns.`,
    updateFailedCampaignFailedSubject: "Alert! Failed Campaign: Not Processed",
    updateFailedCampaignSuccessSubject: "Alert! Failed Campaign Processed Successfully",
    noReplicateForCRMMails: "Replicate is not allowed for the CRM emails.",
    noFollowUpForCRMMails: "Follow Up is not allowed for the CRM emails.",
    rssFieldMissing: "Some of the RSS fields are missing, please enter those to start the campaign.",
    inValidRSSUrl: "Please enter a valid RSS feed URL.",
    previewIssue: "Please update the content correctly to preview.",
    clientFieldForSubscriber:
      "Client field merge tags will pull the values correctly when you choose 'Subscriber' as Recipient.",
    updatePrefDescription: `When recipients click on the "Update Preferences" link, they are directed to a page where they can specify their communication preferences and choose the frequency of emails.`,
    ThankyoupageDescription: `After the poll has been selected, you can choose where to redirect the user. You can redirect them to the Thank You page or the Poll Summary page. The Thank You page is customizable.`,
    nonVerifiedSender: "the campaign sender is not in verified sender list, please verify",
    nonVerifiedRep: "the owner/rep emails are not verified",
    pollCampaignExpiryTime:
      " Poll Expiry Time is required to manage and analyze the poll stats within a specified timeframe.",
    pollExpiredPageDescription:
      "Once the Poll has been expired, we can customize the Thank You Page from here. Ex: We're sorry to say that the poll time has ended.",
    addPollSectionToEmailContent: "Poll section added to email content successfully",
    removePollSectionFromEmailContent: "Poll section removed from email content successfully",
    savePollSectionToEmailContent: "Saved Poll section changes to email content successfully",
    addNewOptionToPollSection: "New {{option}} added to poll section successfully",
    removeOptionFromPollSection: "Removed option from poll section successfully",
    pollExpirationDateEarly: "Poll expiration date can not be earlier than current date.",
    pollExpirationBeforeScheduleDate: "Poll expiration date can not be earlier than schedule date.",
    disabledRepUsedAsSenderMessage: "Click here to display the campaigns having sender as disabled rep.",
    disabledRepUsedAsSenderWarning: "The rep used as a sender has been disabled.",
    aiPromptValidation: "Please enter a prompt or question to get started. The input field cannot be empty.",
    aiError: "An error occurred while fetching data from the AI. Please try again after sometime.",
    aiCustomPromptsError:
      "An error occurred while fetching custom prompts data from AI. Please try again after sometime.",
    aiSubjectPromptValidation: "Please enter a subject to get started. The subject field cannot be empty.",
    aiConversationLost: "Your current conversation will be lost if you close the panel.\nDo you want to proceed?",
    aiPanelCloseWarningWhileStreaming:
      "Content generation in progress. Please do not close the panel until completion.",
    aiMinWordCountError: "To use the AI feature, please type at least three words.",
    pollSummaryReportName: "Go To Poll Voted Summary",
    pollSummaryReportDescription: "Thank you for submitting the poll. Below is the overall Poll Summary Report",
    pollCampaignViewInBrowserMessage: `Poll links don't work in the "View in Browser" mode. They will work in the actual email`,
    disabledRepCheck:
      "The Reps listed below have been disabled. The associated recipients for these disabled Reps will receive the campaign from the default sender",
    disabledRepCheckMergeTags:
      "The following Rep-related merge tags are being used, which will pull values from the disabled Rep",
    chooseDefaultSenderWF: "Choose default sender for unassigned profiles",
    chooseDefaultSenderAll: "Choose default sender",
    repAsSendertext:
      "When using  Rep as Sender, recipients with Unassigned or Disabled Reps will receive the email from the default sender.",
    childWFCampaignContentNotAllowedtoChange: "Changing the template for follow-up campaigns is not permitted.",
    productMappingMessage:
      "By associating the products to campaigns, the number of campaigns sent for the products offered to customers can be tracked.",
    emailAnalyticsAIInfoHoverText:
      "Mirabel's AI for email analytics inquiries. A conversation will reset with any changes in search/views/page refresh.",
    emailAnalyticsAILimitExceededText:
      "Mirabel's AI for email analytics is currently unavailable as it has reached its capacity limit."
  },
  optouts: {
    formDeleteSuccessMesage: "Optout Form deleted successfully",
    deleteConfirmationMessage: "Are you sure you want to delete this Form?",
    defaultFormAlertMessage: "You should have atleast one default form",
    defaultUpadtePrefAlert: "You should have atleast one default Update Preferences",
    sessionExpiryMessage: "Your Session has expired",
    reasonDeleteMessage: "Reason deleted successfully",
    lockFormMessage: "You dont have rights to lock the form as it has not been created by you",
    unsubButtonText: "Please enter the text for Unsubscribe Button",
    formName: "Please enter Form Name",
    reasonText: "Please enter text to create new Reason or choose from the list",
    reasonExist: "Reason exists",
    reasonSuccessMessage: "Reason added successfully",
    formUnSaveMessage: "Changes are not saved, do you want to save the changes?",
    formSaveSuccessMessage: "Form has been saved successfully.",
    invalidRequest: "Invalid Request",
    errorMessage: "Error occured",
    updatePreferencesExists: "Update Preferences name already exists. Please try with other name.",
    unsubscribedText: "You are unsubscribing from:",
    defaultOptoutFormText: "Cannot delete default Optout form"
  },
  preferences: {
    invalidRequest: "Invalid Request"
  },
  optins: {
    formValidationMessage: "Please enter all the valid fields",
    groupValidationMessage: "Please select the Group",
    copyToCRMNoRepMessage: "Please select the Rep",
    lockFormMessage: "You dont have rights to lock the form as it has not been created by you",
    repsErrMessage: "Something went wrong please try later",
    formSaveSuccessMessage: "Form has been saved successfully.",
    deleteConfirmationMessage: "Are you sure you want to delete this Form?",
    formExists: "Form name already exists. Please try with other name",
    fieldsMiss: "Please enter all the fields",
    repicateFormConfirm: "Are you sure you want to replicate the form?",
    title: "Replicate form",
    fileExtensionValidationMessage: "Please select a file extension",
    contactValidationMessage: "Please select a contact",
    tagsAsCheckBoxesEnable: "Please select the Tags As Checkboxes values in the design section.",
    uploadFileAsPfEnable: "Please select the Upload File(s) option in settings tab.",
    uploadFiles: "Upload File(s)",
    disabledCRMRep: "The rep selected for copy to crm is disabled, please select an active rep.",
    disabledCRMRepMessage: "Click here to display the Forms having Copy to CRM with disabled rep",
    actionRequiredForms:
      "Attention: The Rep used in the Copy to CRM has been disabled. Please update the rep and save the form.",
    dynamicFormStepsValidationMessage: "Please add fields in all sections ( {{SECTION_NAMES}} ) before proceeding."
  },
  progressiveMsgs: {
    forminValidMessage: "Please enter required progressive setting fields and All necessary Campaigns.",
    publishProgressiveForm: "Form has been saved and campaigns started successfully.",
    unPublishProgressiveForm: "Form has been saved and campaigns stopped successfully."
  },
  surveyFormMsgs: {
    enterUrlPath: "Please provide a URL path and save.",
    sameUrlPath: "URL path already exist, please provide a different name."
  },
  import: {
    groupaddWarnMessasge: "Please enter GroupName to Add",
    groupsExistsMessage: "GroupName already Exists",
    getGroupsErr: "Something went wrong please try again after sometime.",
    groupSelectMessage: "Please select  Group",
    multigroupFieldsMessage: "Please Check the Field to be used for group import",
    mappingErr: "Please map required field (Email Address).",
    listImportMessage: "List is imported successfully.",
    listDownloadMessage: "List is downloaded successfully",
    getTagsErr: "Something went wrong please try again after sometime.",
    listUnsaveMessage: "You will be loosing your changes,  do you want to continue?",
    listUnsaveCurrentMessage: "You will be loosing your Current changes,  do you want to continue?",
    errMessage: "Error occured while downloading the File",
    typeSelectMessage: "Please select Campaign Types",
    unsubImportMessage: "List is unsubscribed successfully.",
    hardbounceImport: "Hard bounces imported successfully",
    groupsImportError: "Please Correct the Group Names - ",
    fileExtErr: "File extension not supported.",
    fileProcessErr: "Error while Processing uploaded file.",
    invalidFileHeaders: "Invalid header(s) found",
    listExportMessage: "List exported successfully:",
    subscriberUpdateErr: "Error occured while inserting list to subscriber db.",
    segmentUpdateErr: "Error occured while inserting list to segmentation db.",
    overwrite:
      "The profiles will overwrite the old profile fields with the new profile fields. If the profiles doesn't exist in the application, it will import with all the profile field values mapped in the Import File.",
    append:
      "The profiles will append which has empty fields with the new profile fields. If the profiles doesn't exist in the application, it will import with all the profile field values mapped in the Import File.",
    skip: "The profiles will assign to TAGS and GROUPS only and not do any action to profile fields. If the profiles doesn't exist in the application, it will import with all the profile field values mapped in the Import File.",
    workflowAssociationsDescription:
      "The profiles will be enrolled into the below-mentioned workflows. If you do not want the profiles to enroll in the workflows, please select different group(s) and re-import.",
    donotEmailDescription:
      "The `Do Not Email` value should be accepted as YES/NO, TRUE/FALSE, or Y/N, regardless of case sensitivity"
  },
  groups: {
    catAddMessage: "Please enter Category Name",
    groupAddMessage: "Please enter atleast one group name",
    catExistMessage: "Group Category already exists",
    groupSaveMessage: "Group data has been saved",
    groupExistMessage: "Group name already Exists",
    catActivateMessage: "Please Activate the Category before adding group",
    groupNameMessage: "Please enter GroupName to Add",
    groupAddSuccessMessage: "Changes are saved",
    groupActivateMessage: "Please enable  Category to enable  Groups",
    mappingDraft: "Mapping saved as draft successfully",
    groupsMapped: "Groups are mapped successfully",
    contactGrpMappingerr: "Error occured while updating interest groups to the contact groups .",
    GroupUpdateForMail: "Record updated successfully",
    dataSyncMessae: "Please wait while we setup the feature for you.",
    emailServiceUnavailable: "Email Service is not enabled for Client",
    errMessage: "Something went wrong. Please try again later."
  },
  tags: {
    tagDeleteErr: "Error while deleting tag",
    getTagsErr: "Error while getting Tags ",
    getSelectedTagCount: "Error while getting count",
    tagExists: "Tag already exists please create a new tag",
    tagCreated: "Tag is added successfully",
    tagUpdated: "Tag name updated successfully.",
    tagUpdateExists: "Tag already exists please update a new tag"
  },
  disposableDomains: {
    domainsCreated: "Domains are added successfully",
    getDomainsErr: "Error While getting Domains"
  },
  profiles: {
    profileSaveMessage: "Profile Saved Successfully",
    profileEditMessage: "Profile updated successfully.",
    editConfirmation: "Are you sure do you want to continue? {{DATA_VALUES}}.",
    profileSaveErrMessage: "Something went wrong. Please try again.",
    groupSelectMessage: "Select atleast One Group to save the Data",
    groupsTagssaveErrMessage: "Error while saving the Data",
    errMessage: "Something went wrong. Please check later.",
    noProfilesSelected: "No profiles selected",
    assignGrpsMessage: "Select group(s)",
    assignGrpSuccessMessage: "Profile(s) assigned successfully",
    assignGrpsErr: "Assign to groups failed. Please try again.",
    noProfielDownloadMessage: "No profiles available to download",
    profilesExportMessage: "Filtered Profiles exported",
    noprofileSelected: "No profiles selected to download",
    selProfilesExportMessage: "Selected Profiles exported",
    noProfilesForPreview: "No profiles selected to preview",
    segmentemptyMessage: "Segment name can't be empty.",
    segmentSaveMessage: "Segment saved.",
    duplicateSegmentMessage: "Segment Name already exists.",
    segmentName: "Please enter segment name",
    assignSuccess: "Assigned successfully",
    getProfilesErr: "Something went wrong",
    emailExists: "Email Already exists!",
    emailProfileCreated: "Created successfully",
    removeSuccessMsg: "Profile(s) removed successfully",
    removeErr: "Removal of profiles failed. Please try again",
    removeSuccess: "Removed successfully",
    yearmustbeabove: "Year must in between 1700 to 2260 range.",
    defaultMergeTag: "If there is no value for merge tag then the default value configured here will be replaced.",
    deleteProfileSuccess: "Profile deleted Successfully",
    updateProfileFieldsSuccess: "Profile updated Successfully",
    profileFieldsAssociatedTitle: "Below {{TYPE}} using this field will be update",
    updateEmailSucess: "Profile updated successfully. You can send campaigns after email is validated",
    emailExistsUpdate: "You cannot update email with the same email",
    deleteCRMProfile: "Unable to delete as it is CRM Profile",
    updateCRMProfile: "Unable to update as it is CRM Profile",
    updateCRMMessage:
      "Profile field cannot be updated here for the profiles that exist in CRM. Update it in the CRM & changes will reflect here automatically after sometime.",
    unsubscribeProfileSuccess: "Profile unsubscribed Successfully",
    removeProfilesFromTags: "The profiles are removed from selected Tag(s) successfully.",
    removeProfilesFromGroups: "The profiles are removed from selected Group(s) successfully.",
    notArchiveCRMProfile: "CRM contacts cannot be archived",
    backgroundJobExists: "An existing background job is present for the current operation, please try after some time"
  },
  templates: {
    getTemplatesErr: "Unable to fetch template details",
    templateSelectForDelete: "Please select a template to delete",
    templateDeleteConfirm: "Are you sure want you to delete this template ?",
    templateDeleteErr: "Unable to delete template",
    templateDeleteSuccessMessage: "Template deleted successfully",
    templateFetchErr: "Unable to fetch Blank template details",
    templateSelectForRename: "Please select a template to rename",
    templateRenameErr: "Unable to rename template",
    templateRenameSuccessMessage: "Template renamed successfully"
  },
  segments: {
    listExportMessage: "List exported successfully.",
    errMessage: "Something went wrong. Please try again later",
    listExportNoContacts: "List does not have any contact to export.",
    assignGroupsSuccess: "Segment is assigned.",
    segmentReplicated: "Segment replicated.",
    segmentUnableToDelete: "This segment cannot be deleted as this is used in recent campaigns",
    segmentCampDelete: "This campaign can be deleted",
    segmentDeleteComfirmation: "Are you sure you want to delete this segment?",
    segmentDeleted: "Segment deleted",
    selectWebsite: "Please select website",
    selectDataset: "Please select dataset to start your segment",
    segmentChangeWarning: "You cannot change segment as It's associate with campaign",
    selectGroup: "Please select group(s)",
    segmentChanged: "Changes are saved for the segment.",
    duplicateSegmentMessage: "Segment Name already exists.",
    segmentSaveMessage: "Please save your changes",
    dragStaticSegment: "Selection cannot be dragged as segment is static.",
    nameStaticSegment: "Segment name cannot be changed as segment is static.",
    selectionRemoveStaticSegment: "Selection cannot be removed as segment is static.",
    websiteChangeStaticSegment: "Website cannot be changed as segment is static.",
    segmentNameMessage: "Please provide Segment Name",
    segmentTypeMessage: "Please select Segment Type",
    enterSegmentName: "Please enter segment name",
    segmentSaved: "Segment saved successfully.",
    saveGroupsChange: "Please save  group(s) changes.",
    selectOneWebsite: "Please select atleast one website.",
    selectAtleastOneWebsite: "At least one website should be selected.",
    selGroupsAssigned: "Selected groups assigned successfully",
    selTagsAssigned: "Selected tags assigned successfully",
    assignGroupsErr: "Assign to groups failed. Please try again.",
    assignTagsErr: "Assign to tags failed. Please try again.",
    segmentLeaveConfirmation: "Changes are not saved. Do you want to save the changes?",
    exportLimit: "Export not allowed for more than 200,000 records",
    dateRangeFilterDateCheck: "To date should be greater than From date.",
    numberRangeFilterDateCheck: "To should be greater than From.",
    numberRangeFilterNumberCheck: "Value should not be empty.",
    currentFutureDateCheck: "Date should not be past date for current or future orders.",
    daysWeeksMonthsZeroCheck: "Days/Weeks/Months should be greater than zero",
    CSBProfilesCheck: "Please select atleast one profile to mark as invalid",
    selGroupsAssignInProgress: "Processing assignment request.Please Check the progess in background jobs."
  },
  workflows: {
    addConditionNodeV1: `You are adding condition in the middle of the nodes.All the child nodes will be moved to either left or right. Which side do u wanted to move? enter "left" or "right"`,
    addConditionNode: `Which side the child nodes should move ? please  enter "left" or "right"`,
    workflowNameEmpty: `Workflow name can't be empty.`,
    workflowDescEmpty: `Workflow description can't be empty.`,
    workflowCreated: "Workflow created successfully.",
    workflowUpdated: "Workflow updated successfully.",
    deleteWorkflowConf: "Are you sure you want to delete this workflow?",
    deleteWorkflow: "Workflow deleted successfully",
    enterWorkflowName: "Please enter workflow name ",
    workflowPublished: "Workflow published successfully",
    workflowPublishConfirm: "Are you sure you want to publish the workflow?",
    workflowUnpublishWithActive: "There are active enrollment(s).Do you want to process them further or terminate?",
    workflowUnpublishWithoutActive: "Once workflow is unpublished no enrollments will be registered.",
    worflowUnpublishWhileSavingWithNoEnrollments:
      "Saving the published workflow will unpublish the workflow.Do you want to proceed?",
    workflowDeleteCondition: "Deleting condition will delete all the children. Do you want to proceed?",
    workflowDeleteNode: "Once you delete, you cannot undo this action. Are you sure you want to delete the node?",
    workflowWithNoActions: "Workflow should contain atleast one action.",
    workflowInsufficientTriggerDetails: "Insufficient details  provided for trigger.",
    workflowInvalidNodesData:
      "Below nodes doesn't have sufficient data -  {{invalidNodes}} . Please enter the data and try again.",
    workflowAtleastReqAction:
      "Workflow should contain atleast one required action like Send Email,Add Or Remove Tags etc.",
    workflownoDataExistsForStepId: "Stepid missing in the request.",
    workflowToTimeGreater: "EMail send Fromtime is greater than Totime",
    workflowFromTimeLesser: "EMail send Totime is less than Fromtime",
    workflowInvalidDays: "Please adjust the day(s) or time for the current action",
    workflowSettingsInvalidDays: "Delay time should be within the specified time in workflow settings",
    restrictInvalidEmails: "Email is not valid",
    workflowNodeSave: "Please save the unsaved actions in the workflow to proceed.",
    workflowValidateError: "Without saving the actions, the workflow cannot be published.",
    resetAvoidDuplicateEmails:
      "'Do not send duplicate Emails' option on the parent Email '{{campaignName}}' will be removed as you are adding a Send Sequence Email",
    updateWorkflowSettingsConfirm: "Are you sure you want to update the workflow settings?",
    enrollPreviousOrdersConfirm:
      "The workflow may enroll existing orders falling within the selected date criteria that are eligible for enrollment. Do you wish to proceed?",
    disabledCRMRep: "The rep selected for copy to crm is disabled, please select an active rep.",
    disabledCRMRepMessage: "Click here to display the workflows having Copy to CRM with disabled rep",
    actionRequiredWfs: "Attention: The Rep used in the Copy to CRM has been disabled",
    allowedEnrollmentDateSurpassed:
      "Attention: Enrollment end date surpassed as per the workflow settings. Modify the date to allow enrollments (or) you may unpublish the workflow if it is no longer required.",
    manualTerminationForJobJacketOrdersConfirm: `Below orders are part of shared job jacket.<br /><strong>Orders: {{ORDERIDS}}</strong> <br /><br />Terminating these orders will terminate other orders part of shared job jacket.`,
    workflowSettingemailSendTime:
      "While choosing to send emails on specific days, if a particular time is not selected, the email will be sent at 9 AM on the chosen day. "
  },
  landingPages: {
    deleteLPConfirmation: "Are you sure you want to delete the {{type}}?",
    deleteLP: "{{type}} deleted successfully",
    landingPageUnpublishWithoutActive: "Once Landing page is unpublished, no submissions will be captured.",
    popUpPageUnpublishWithoutActive: "Once popup page is unpublished, no submissions will be captured.",
    landingPagePublishConfirm: "Are you sure want to publish the Landing page?",
    deleteLPError: "Error occured while deleting Landing page. Please try again later",
    errMessage: "Something went wrong. Please try again.",
    duplicateLPTemplate: "{{type}} Template Name cannot be duplicate",
    duplicateCPTemplate: "Checkout Page Template Name cannot be duplicate",
    saveLPTemplateSuccess: "{{type}} Template saved successfully",
    saveCPTemplateSuccess: "Checkout Page Template saved successfully",
    deleteLPTemplateConfirmation: "Do you want to delete the Landing page template?",
    deleteLPTemplate: "Landing page template deleted successfully",
    removeHtml: "If you change your selection, your existing template will be cleared. Do you want to proceed?",
    removeForm: "Are you sure do you want to remove the form?",
    SaveLpWithoutForm:
      "Are you saving Landing Page without Form? <br/><br/>" +
      " <b>Tip: </b> Place the {{INSERT_FORM}} merge tag into HTML code to save with selected form.",
    inValidRedirectUrl: "Please enter valid Auto-Redirect URL",
    formNotMatched: "Form pattern not found. Please remove the form manually in html.",
    mandatoryFormCheck:
      "{{type}} has Form object(s) without Form(s). Select the Form(s) or Delete the Form object(s) & retry.",
    formAddedHtmlLp: "Selected form added to HTML Editor.",
    addFormHtmlLp: "Please add the merge tag linked with the form to HTML editor.",
    selectFormHtmlLp: "Please select a form from Edit Actions.",
    stickyHelperText: "Sticks the section on top while scrolling through the rest of the Landing Page",
    dragStickyHeaderBelow:
      "Sticky Header section cannot be placed below other objects, uncheck the 'Sticky Header' setting & try again.",
    dragStickyHeaderAbove: "Objects cannot be placed above a Sticky Header.",
    urlRedirectAlert: "Same Landing Page URL cannot be used for URL re-direction. Please use a different URL.",
    sectionExists: "Section already exists.",
    deleteSection: "Section deleted successfully",
    deleteSectionConfirmation: "Do you want to delete the Saved Section?",
    portalSettingSuccess: "Checkout page portal setting updated successfully",
    portalSettingFailed: "Checkout page portal setting updation failed",
    popSettingEnable: "Please enable any one of the Pop Up Page Settings from settings tab to publish the Pop Up Page.",
    landingPageView: "Click here to view the {{TYPE}}",
    landingPageEdit: "Click here to edit the {{TYPE}}"
  },
  webhookConfig: {
    webhookConfigNameExists: "Webhook config name already exists",
    deletedWebhook: "webhook deleted successfully",
    webhookNotDeleted: "webhook not deleted.",
    deleteConfirmation: "Are you sure you want to delete this webhook?",
    URLCopied: "URL copied",
    deleteWebhookTitle: "Delete webhook",
    saveWebhookMessage: "Webhook saved successfully.<br/> Please use below URL to post data <br/>",
    savePostWebhookMessage: "Webhook saved successfully.",
    emptyWebhookName: "Webhook name should not be empty.",
    emptyType: "Type cannot be empty.",
    emptyWebsiteName: "choose website from the Website List",
    emptyFormName: "choose Form Name from the Form List",
    clearMappingsText: "Selected Mapping(s) will be cleared. Do you want to proceed?",
    clearMappingsTitle: "Clear Mappings",
    emptyForDelimiter: "Separator/Delimiter cannot be empty for",
    payloadCopied: "Payload copied"
  },
  fileExplorer: {
    deletedFile: "File(s) deleted successfully",
    fileNotDeleted: "File(s) not deleted",
    deleteFolder: "Folder deleted successfully",
    folderNotDeleted: "Folder not deleted",
    folderNameValidation: "Folder Name should contain characters and numbers",
    emptyFolderName: "Folder Name is Empty",
    createdFolder: "Folder created successfully",
    folderExists: "Folder already exists",
    folderNotCreated: "Folder not created",
    uploadOnlyFiles: "uploaded File is not an image",
    filesValidation: "please upload only images",
    fileCreated: "File created Successfully",
    deletedFileConfirmation: "Are you sure you want to delete the file?",
    deletedFileTitle: "Delete file",
    deletedFolderConfirmation: "Are you sure you want to delete the Folder?",
    deleteFolderTitle: "Delete Folder",
    exceededFileSize: "File size shouldn't exceed 4MB",
    fileAssociationsMsg: "Selected file references will break if you do this activity.",
    fileMoveRestriction: "Moving files within the same folder is not permitted.",
    fileMoveNotAllowed: "Please select a folder to move the file.",
    filesWithSameNameExistError: "File with the same name exists at destination folder.",
    fileSelectionLimitExceeded: "You can not select more than 5 files."
  },
  disclaimer: {
    deleteConfirmation: "Are you sure you want to delete the Disclaimer?",
    deleteAlert: "Disclaimer deleted successfully",
    atleastOne: "You cannot delete this disclaimer. Atleast one disclaimer should be available.",
    errorMsg: "Something went wrong please try again later.",
    editDisclaimer:
      "Disclaimer Saved successfully. You need to replublish you Form/Landing Page that are linked with this disclaimer.",
    saveDisclaimer: "Disclaimer Saved successfully",
    checkDuplicates: "Disclaimer already exists in list",
    formAssociation: "You cannot delete this disclaimer as it is linked with the existing forms/Landing Pages."
  },
  adminAccess: {
    enabledConfirmation: "Enabled for users successfully.",
    disabledConfirmation: "Disabled for users successfully.",
    errorMsg: "Something went wrong please try again later.",
    MKMDisabled: "You do not have access to Marketing Manager, please contact your administrator"
  },
  clientReputationSummary: {
    campaignResumeConfirmation: "Are you sure you want to resume campaign ?",
    resumeSuccessAlert: "Enabled mail sending capability",
    onhoverClientSummary: "The client has no mail sending capability as {{REASONS}} exceeds its threshold value",
    onhoverSendLimitMessage: "The client has no mail sending capability as Send limit reaches its threshold value",
    emailsSent: "Emails sent to total number of recipients which includes Regular and AB campaign.",
    deliveryRate:
      "Calculated by subtracting hard and soft bounces from the gross number of emails sent, then dividing that number by gross emails sent.",
    spamRate:
      "Spam rate is calculated by how many of a company's emails are reported as spam, also known as junk mail, divided by gross number of delivered emails.",
    openRate:
      "Email open rate is the percentage of people who open a specific email out of the total number of gross emails delivered.",
    clickRate:
      "The email click rate is the percentage of people who click on a link or image within an email. Calculated by number of clicks divided by total number of emails delivered.",
    softBounce:
      "Soft bounces are email addresses which are valid but not reached the inbox because of (Mailbox full, Server down, message was too large etc.). Calculated by total soft bounces divided by gross number of emails sent.",
    hardBounce:
      "A hard bounce occurs when the message has been permanently rejected either because - The email address is invalid, or the email addresses does not exist. Calculated by total hard bounce divided by gross number of emails sent.",
    unsubscriberAll: "Number of profiles unsubscribed",
    lastCampaignSentDate: "It shows when was the last campaign sent.",
    lastCampaignDraftDate: " It shows when was the last campaign was in draft status.",
    lastCampaignScheduleDate: "It shows when was the last campaign scheduled.",
    cSB: "It shows the profiles which are having 5 or more consecutive soft bounces",
    domainExists: "Domain Already Added",
    domainAddedSuccess: "Domain Added SuccessFully",
    domainDeletedSuccess: "Domain deleted SuccessFully",
    deleteConfirmation: "Are you sure you want to delete the sender domain?",
    TrackingDomainAddedSuccess: "Tracking domain Added SuccessFully",
    TrackingDomainUpdatedSuccess: "Tracking domain updated SuccessFully",
    clientSettingsSuccess: "Client Settings Updated SuccessFully",
    clientSettingsEmpty: "Google Site Key, Google Site Secret and Time Zone should not be empty"
  },
  checkoutForm: {
    repicateFormConfirm: "Are you sure you want to replicate the form?",
    title: "Replicate Checkout Form",
    saveFormMessage: "Form Saved successfully",
    publishConfirm: "Are you sure you want to publish the form?",
    publishSuccess: "Checkout Form published successfully",
    unpublishConfirm: "Once Checkout form is unpublished no subscriptions will be registered?",
    unpublishSuccess: "Checkout Form unpublished successfully",
    deleteCFConfirmation: "Do you want to delete the Checkout Form?",
    deleteCF: "Checkout Form deleted successfully",
    noPlanSelected: "Please choose atleast one plan",
    formExists: "Form Name already Exists",
    overrideTitle: "The title applied in this step will override across all the steps. Are you sure?",
    overrideStyles: "The styles applied in this step will override across all the steps. Are you sure?",
    overrideTitleText: "Override Title",
    overrideStylesText: "Override Styles"
  },
  checkoutPages: {
    deleteCPConfirmation: "Do you want to delete the Checkout page?",
    deleteCP: "Checkout page deleted successfully",
    CheckoutPageUnpublishWithoutActive: "Once Checkout page is unpublished, no submissions will be captured.",
    checkoutPagePublishConfirm: "Are you sure want to publish the Checkout page?",
    deleteCPError: "Error occured while deleting Checkout page. Please try again later",
    errMessage: "Something went wrong. Please try again.",
    duplicateCPTemplate: "Checkout Page Template Name cannot be duplicate",
    saveLPTemplateSuccess: "Checkout Page Template saved successfully",
    deleteCPTemplateConfirmation: "Do you want to delete the Checkout page template?",
    deleteCPTemplate: "Checkout page template deleted successfully",
    mandatoryFormForPublish:
      "Checkout Page cannot be published without a Checkout Form. Select a Checkout Form and retry",
    mandatoryFormForTemplate:
      "Checkout Page template cannot be created without a Checkout Form. Select a Checkout Form and retry."
  },
  themePage: {
    themeExists: "Theme Name already exists",
    invalidThemeId: "Invalid theme id",
    themePageConfirm: "This will update all the checkout forms that use this theme. Are you sure you want to continue?",
    themePageTitle: "Theme page"
  },
  profileFields: {
    copyMergeField: "Merge Field copied"
  },
  invalidToken: {
    invalidTokenMsg: "Invalid URL, unable to display the email"
  },
  giftCheckboxes: {
    giftPageTitle: "Gift Checkbox",
    giftPageConfirm: "Are you sure want to reset the gift checkbox?This will delete your additional subscriber"
  },
  pollthankyouPages: {
    defaultThankyoupageText: "Cannot delete default Poll Thank you Page",
    defaultThankyoupageTextAlertMessage: "You should have atleast one default Poll Thank you Page",
    thankyouPageDeleteSuccessMesage: "Poll Thank you Page deleted successfully"
  },
  expiredpollthankyouPages: {
    defaultExpiredThankyoupageText: "Cannot delete default Expired Poll Thank you Page",
    defaultExpiredThankyoupageTextAlertMessage: "You should have atleast one default Expired Poll Thank you Page",
    expiredthankyouPageDeleteSuccessMesage: "Expired Poll Thank you Page deleted successfully"
  },
  dynamicForms: {
    mappingSidePanelHelpText:
      "Map current section ( {{sectionName}} ) with next section navigation based on user input values.",
    mappingSidePanelHelpTexteg:
      "For example, if the user selects 'Option 1,' then redirect them to the next section by selecting 'Go to next section' or any other available section.",
    mappingValidationText:
      "All your navigations are leading back to previous sections, which could result in a loop that doesn't end. Please check your selections.",
    numberTypeFieldValidationText: "Some conditions are missing values in mapping. Please complete all fields."
  }
};
module.exports = AppMessages;
